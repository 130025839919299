import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import ContactForm from "../components/contact";
import Layout from "../components/layout";
import Media from "../helpers/Media";
const media = new Media();

const ContactTemplate = ({data, pageContext, location}) => {
    const {images, gifs} = data;
    let {uris, section, homeFeaturedPosts, categories} = pageContext;
    const indexed = {
        images: media.indexImagesByFilename(images),
        gifs: media.indexGifsByFilename(gifs)
    };
    
    return (
        <Layout 
            homeFeaturedPosts={homeFeaturedPosts} 
            indexedImages={indexed}
            uris={uris}
            section={section}
            subsection='' 
            categories={categories}
            location={location}
        >
            <div className="row">
                <ContactForm /> 
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Contact ($images: [String!]!, $gifs: [String!]!) {
        images: allFile(filter: {name: {in: $images}}) {
            nodes {
              childImageSharp {
                fluid(maxWidth: 500, quality: 75) {
                    originalName
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
        gifs: allFile(filter: {name: {in: $gifs}}) {
            nodes {
                name
                publicURL
                base
                dir
            }
        }
      }
    `;

ContactTemplate.propTypes = {
    categories: PropTypes.object
}

ContactTemplate.defaultProps = {
    categories: {}
}

export default ContactTemplate;