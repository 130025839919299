import React from "react";

export default class ContactForm extends React.Component {
    state = {
        status: ''
    };

    render() {
        const { status } = this.state;
        return (
            <form
                onSubmit={this.submitForm}
                action="https://formspree.io/mlewlvky"
                method="POST"
            >
                <fieldset>

                    <div className="form-field">
                        <input name="name" type="text" className="full-width" placeholder="Nombre" />
                    </div>
                    <div className="form-field">
                        <input name="email" type="text" className="full-width" placeholder="Email" />
                    </div>
                    <div className="form-field">
                        <input name="subject" type="text" className="full-width" placeholder="Asunto" />
                    </div>
                    <div className="message form-field">
                        <textarea name="message" className="full-width" placeholder="Mensaje"></textarea>
                    </div>

                    {status === "SUCCESS" ? 
                            <div className="alert-box alert-box--success hideit">
                                <p>Mensaje enviado correctamente.</p>
                            </div>
                        : 
                            <button type="submit" className="submit btn btn--primary full-width">Enviar</button>
                    }
                    {status === "ERROR" && 
                        <div className="alert-box alert-box--error hideit">
                            <p>Rellena todos los campos e inténtalo de nuevo.</p>
                        </div>
                    }
                </fieldset>
            </form>
        );
    }

    submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: "SUCCESS" });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }
}